<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :disable-sort="true"
    :items="items"
    class="dataTable elevation-1"
    calculate-widths
    hide-default-footer
    disable-pagination
    fixed-header
    mobile-breakpoint="0"
    hide-default-header
  >
    <!-- template no data -->
    <template #no-data>
      <div class="d-flex justify-center align-center flex-column">
        <v-img
          :src="require('../assets/NoData.png')"
          style="max-width: 40vw"
          contain
        />
        <span class="text-lg">Aún no has realizado ningún viaje</span>
        <v-btn
          class="my-4 text-base"
          color="primary"
          rounded
          outlined
          small
          @click="handleCreate"
          style="text-transform: none"
        >
          {{ $t(noDataButtonText) }}
        </v-btn>
      </div>
    </template>

    <template #item.user_picture="{ item }">
      <v-row class="mt-2 mb-2">
        <v-col cols="12">
          <v-avatar size="32" class="mr-2">
            <v-img
              v-if="item.user_picture"
              :src="`https://riderys3bucket.s3.amazonaws.com/${item.user_picture}`"
              cover
              class="rounded-circle"
            />
            <v-img
              v-else
              :src="require('../assets/UserIcon.jpg')"
              cover
              class="rounded-circle"
            />
          </v-avatar>
          <span>{{ item.user_name }}</span>
        </v-col>
      </v-row>
    </template>

    <template #item.date="{ item }">
      <td>
        <span
          v-if="!item.server_start_time_for_schedule"
          style="white-space: nowrap"
        >
          {{ item.created_at | moment("DD-MM-YYYY h:mm a") }}
        </span>
        <span v-else style="white-space: nowrap">
          {{
            item.server_start_time_for_schedule | moment("DD-MM-YYYY h:mm a")
          }}
        </span>
      </td>
    </template>

    <template #item.typename="{ item }">
      <v-chip
        outlined
        :class="$vuetify.theme.dark ? 'white--text' : 'primary--text'"
        class="v-chip-light-bg mx-2 mb-2"
        :color="$vuetify.theme.dark ? 'white' : 'primary'"
      >
        {{ item.typename }}
      </v-chip>
      <v-chip
        v-if="item.is_special_trip"
        outlined
        class="v-chip-light-bg warning--text mx-2 mb-2"
        color="warning"
      >
        {{ $t("special_trip") }}
      </v-chip>
      <v-chip
        v-if="item.is_multi_stop"
        outlined
        :class="$vuetify.theme.dark ? 'white--text' : 'primary--text'"
        class="v-chip-light-bg mx-2 mb-2"
        :color="$vuetify.theme.dark ? 'white' : 'primary'"
      >
        {{ $t("multi_stop") }}
      </v-chip>
    </template>

    <template #item.trip_status="{ item }">
      <v-chip
        v-if="item.trip_status === 'Cancelled' || item.trip_status === 'Cancelled by provider'"
        class="v-chip-light-bg error--text"
        color="error"
      >
        {{ $t(item.trip_status) }}
      </v-chip>
      <v-chip
        v-else-if="item.trip_status === 'Completed'"
        class="v-chip-light-bg success--text"
        color="success"
      >
        {{ $t(item.trip_status) }}
      </v-chip>
      <v-chip
        v-else-if="
          item.trip_status === 'Waiting' && item.server_start_time_for_schedule
        "
        class="v-chip-light-bg blue--text"
        color="blue"
      >
        {{ $t("Scheduled") }}</v-chip
      >
      <v-chip v-else class="v-chip-light-bg warning--text" color="warning">
        {{ $t("In progress") }}
      </v-chip>
    </template>

    <template #item.actions="{ item }">
      <v-btn
        @click="handleViewDetails(item)"
        dense
        small
        rounded
        style="text-transform: none"
        class="text-sm"
      >
        <v-icon> {{ icons.mdiEye }} </v-icon>
        <span class="pl-2">{{ $t("View trip") }}</span>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mdiEye } from "@mdi/js";
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    noDataUrl: {
      type: String,
      required: true,
    },
    noDataButtonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return { icons: { mdiEye } };
  },
  methods: {
    handleViewDetails(item) {
      let route = this.$router.resolve({
        name: "ViewMap",
        params: { id: item._id },
      });
      window.open(route.href, "_blank").focus();
    },
    handleCreate() {
      this.$router.push({
        name: this.noDataUrl,
      });
    },
  },
};
</script>
