<template>
  <v-card class="balance-card" outlined>
    <template v-if="loading">
      <v-skeleton-loader
        class="pa-4 skeleton-custom-height"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </template>
    <template v-else>
      <v-row class="d-flex justify-start pa-4 pb-0">
        <v-col cols="3" class="d-flex justify-start">
          <v-img :src="icon" contain style="max-width: 2.6rem !important"></v-img>
        </v-col>
        <v-col cols="9" class="d-flex justify-end">
          <v-btn
            v-if="isButton"
            rounded
            :color="buttonColor"
            @click="onButtonClick"
            :outlined="outlinedButton"
            small
            class="text-sm"
            style="text-transform: none"
          >
            {{ buttonText }}
          </v-btn>
          <router-link v-else :to="routerTo" class="text--primary">
            {{ buttonText }}
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-start flex-column pt-0 pl-6 pb-6">
          <span>{{ title }}</span>
          <span v-if="currency" class="text--primary text-2xl">{{ value | currency }}</span>
          <span v-else class="text--primary text-2xl">{{ value }}</span>
          <span class="">{{ status }}</span>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    status: {
      type: String,
      default: "Disponible",
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      default: "#00C49A",
    },
    outlinedButton: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    routerTo: {
      type: [String, Object],
      default: "/",
    },
  },
  methods: {
    onButtonClick() {
      this.$emit("button-click");
    },
  },
};
</script>

<style scoped>
.balance-card {
  margin: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.value {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.status {
  text-align: center;
  color: gray;
}

.skeleton-custom-height {
  min-height: 170px;
}
</style>
