<template>
  <div class="fontUrbanist">
    <h2>{{ $t("Welcome") }}, {{ name }}</h2>
    <span class="text-sm">
      {{ $t("Updated") }} {{ updated_at | moment("YYYY-MM-DD hh:mm A") }}</span
    >

    <v-row class="mt-2">
      <v-col
        v-for="(card, index) in visibleDashboardCards"
        :key="index"
        class="ma-0 pa-0"
        cols="12"
        :sm="visibleDashboardCards.length === 3 && index === 2 ? 12 : 6"
        :md="12 / visibleDashboardCards.length"
      >
        <DashboardCards
          :icon="require(`./assets/${card.icon}`)"
          :title="$t(card.title)"
          :value="card.value || 0"
          :buttonText="$t(card.buttonText)"
          :buttonColor="card.buttonColor"
          :outlinedButton="card.outlinedButton"
          @button-click="card.onClick"
          :status="$t(card.status) + (card.value === 1 ? '' : 's')"
          :currency="card.currency"
          :loading="card.loading"
          :routerTo="card.routerTo"
          :isButton="card.isButton"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0 pt-0">
      <v-col class="mb-0 pb-0">
        <v-card
          class="ma-0 pa-0 d-flex align-center justify-space-between pr-4"
        >
          <v-card-title class="text--primary">{{ $t("Summary") }}</v-card-title>
          <div class="d-flex align-center">
            <router-link
              :to="
                selectedTab === 0 || !is_active_shipments
                  ? '/history'
                  : '/parcel_history'
              "
              class="text--primary"
            >
              {{ $t("See more") }}
            </router-link>
            <v-btn
              icon
              outlined
              small
              class="ml-2"
              :loading="loadingDashboard"
              @click="refreshDashboard"
            >
              <v-icon>{{ icons.mdiRefresh }}</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-0 py-2 mb-6">
      <v-col class="mt-0 pt-0">
        <template v-if="is_active_shipments">
          <v-tabs v-model="selectedTab">
            <v-tab>
              <v-icon>{{ icons.mdiCar }}</v-icon>
              <span class="ml-2 text-base" style="text-transform: none">{{
                $t("Trips")
              }}</span>
            </v-tab>
            <v-tab>
              <v-icon>{{ icons.mdiPackageVariantClosed }}</v-icon>
              <span class="ml-2 text-base" style="text-transform: none">{{
                $t("Shipments")
              }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item>
              <DataTable
                :items="recentTrips"
                :headers="headers"
                :loading="loadingTrips || loadingDashboard"
                noDataUrl="runningTrips"
                noDataButtonText="Create trip"
              />
            </v-tab-item>
            <v-tab-item>
              <DataTable
                :items="recentShipments"
                :headers="headers"
                :loading="loadingTrips || loadingDashboard"
                noDataUrl="runningParcels"
                noDataButtonText="Create shipment"
              />
            </v-tab-item>
          </v-tabs-items>
        </template>

        <template v-else>
          <DataTable
            :items="recentTrips"
            :headers="headers"
            :loading="loadingTrips || loadingDashboard"
            urlName="runningTrips"
          />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mdiPackageVariantClosed, mdiCar, mdiRefresh } from "@mdi/js";
import DashboardCards from "./parts/DashboardCards.vue";
import DataTable from "./parts/DataTable.vue";

export default {
  components: {
    DashboardCards,
    DataTable,
  },
  data() {
    return {
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      selectedTab: 0,
      headers: [
        {
          text: "user_picture",
          value: "user_picture",
          align: "start",
        },
        { text: "date", value: "date", align: "center" },
        { text: "type", value: "typename", align: "center" },
        { text: "status", value: "trip_status", align: "center" },
        { text: "actions", value: "actions", align: "end" },
      ],
      icons: {
        mdiCar,
        mdiPackageVariantClosed,
        mdiRefresh,
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", [
      "name",
      "picture_url",
      "unique_id",
      "is_corporate_master",
      "wallet",
      "updated_at",
      "is_active_shipments",
    ]),
    ...mapState("dashboard", [
      "loadingDashboard",
      "loadingUsers",
      "totalCollaborators",
    ]),
    ...mapGetters("dashboard", ["getTotals", "getDetails"]),
    recentTrips() {
      return this.getDetails.trips || [];
    },
    recentShipments() {
      return this.getDetails.shipments || [];
    },
    totalTrips() {
      return this.getTotals.trips || 0;
    },
    totalShipments() {
      return this.getTotals.shipments || 0;
    },
    loadingTrips() {
      return this.loadingDashboard && !this.recentTrips.length;
    },
    dashboardCards() {
      return [
        {
          icon: "WalletIcon.png",
          title: "Balance",
          value: this.wallet,
          buttonText: "Recharge",
          buttonColor: "success",
          outlinedButton: false,
          onClick: () => {
            this.$router.push("/balance");
          },
          currency: true,
          status: "Available",
          show: true,
        },
        {
          icon: "CarIcon.png",
          title: "Trips this month",
          value: this.totalTrips,
          buttonText: this.totalTrips === 0 ? "Create trip" : "See more",
          buttonColor: "primary",
          outlinedButton: true,
          onClick: () => {
            this.$router.push({
              name: "runningTrips",
            });
          },
          status: "Completed",
          currency: false,
          loading: this.loadingTrips || this.loadingDashboard,
          show: true,
          routerTo: {
            name: "runningTrips",
          },
          isButton: this.totalTrips === 0,
        },
        {
          icon: "ShipmentIcon.png",
          title: "Shipments this month",
          value: this.totalShipments,
          buttonText:
            this.totalShipments === 0 ? "Create shipment" : "See more",
          buttonColor: "primary",
          outlinedButton: true,
          onClick: () => {
            this.$router.push({
              name: "runningParcels",
            });
          },
          status: "Completed",
          loading: this.loadingTrips || this.loadingDashboard,
          show: this.is_active_shipments,
          routerTo: {
            name: "runningParcels",
          },
          isButton: this.totalShipments === 0,
        },
        {
          icon: "CollaboratorsIcon.png",
          title: "Collaborators",
          value: this.totalCollaborators,
          buttonText: this.totalCollaborators === 0 ? "Add" : "See more",
          buttonColor: "primary",
          outlinedButton: true,
          onClick: () => {
            this.$router.push({
              name: `${this.is_corporate_master ? "/corporate_panels" : "/corporate_users"}`,
            });
          },
          status: "Active",
          currency: false,
          loading: this.loadingUsers,
          show: true,
          routerTo: `${this.is_corporate_master ? "/corporate_panels" : "/corporate_users"}`,
          isButton: this.totalCollaborators === 0,
        },
      ];
    },
    visibleDashboardCards() {
      return this.dashboardCards.filter((card) => card.show);
    },
  },
  methods: {
    ...mapActions({
      setLoading: "setLoading",
      meNotLoading: "auth/meNotLoading",
      isActiveShipments: "auth/is_active_shipments",
      fetchDashboardData: "dashboard/fetchDashboardData",
      fetchUsers: "dashboard/fetchUsers",
    }),
    async refreshDashboard() {
      try {
        await this.fetchDashboardData({ forceRefresh: true });
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
    },
  },
  created() {
    this.fetchUsers();
    this.fetchDashboardData();
    this.meNotLoading();
  },
};
</script>
<style lang="scss">
.dashboard {
  &-banner-container {
    width: 100%;
    height: 320px;
    &-image {
      border-radius: 10px;
    }
  }
}

@import "~vuetify/src/styles/styles.sass";
.triangle-card {
  position: relative;
  .triangle-bg {
    position: absolute;
    bottom: 0;
    @include ltr() {
      right: 0;
    }
    @include rtl() {
      left: 0;
    }
  }
  .gamification-trophy {
    position: absolute;
    bottom: 10%;
    @include ltr() {
      right: 8%;
    }
    @include rtl() {
      left: 8%;
    }
  }
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  @include ltr() {
    right: 0;
  }
  @include rtl() {
    left: 0;
  }
}
.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 15%;
  }
  @include rtl() {
    left: 15%;
  }
}
.gamification-tree-4 {
  bottom: 0;
  @include ltr() {
    right: 30%;
  }
  @include rtl() {
    left: 35%;
  }
}
@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    @include ltr() {
      right: 5%;
    }
    @include rtl() {
      left: 5%;
    }
  }
}
@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}
@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
